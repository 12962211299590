.service-details-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 30px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.service-title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.service-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.service-info {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two-column layout */
    gap: 15px;
    margin-top: 10px;
}

.info-item {
    background: #e9ecef;
    padding: 10px;
    border-radius: 5px;
    color: #333;
}

.hostname-link {
    color: #007bff;
    text-decoration: none;
}

.hostname-link:hover {
    text-decoration: underline;
}

.state-indicator {
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
}

.state-indicator.running {
    background-color: #28a745; /* Green */
}

.state-indicator.stopped {
    background-color: #dc3545; /* Red */
}

.state-indicator.error {
    background-color: #dc3545; /* Red for errors */
}

.service-section {
    margin-top: 30px;
}

.service-section h4 {
    margin-bottom: 10px;
    color: #007bff; /* Blue for headers */
}

.mounts-list {
    list-style-type: none;
    padding-left: 0;
}

.mount-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 8px;
    background-color: #ffffff;
}

.loading,
.error {
    text-align: center;
    font-size: 18px;
}

.loading {
    color: #007bff; /* Bootstrap primary color */
}

.error {
    color: #dc3545; /* Bootstrap red */
}
