.dashboard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure full height */
}

.dashboard-header {
    background-color: #4CAF50; /* Header background color */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding around header */
    display: flex;
    justify-content: space-between; /* Space between elements */
    align-items: center; /* Center vertically */
}

.dashboard-main {
    flex: 1; /* Take remaining space */
    padding: 20px; /* Padding around main content */
    background-color: #f9f9f9; /* Light background */
}

.dashboard-summary {
    margin-bottom: 20px; /* Space below summary */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between cards */
}

.card {
    background: #f0f0f0; /* Card background color */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Inner spacing */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    flex: 1 1 calc(30% - 20px); /* Adjust the size */
    min-width: 200px; /* Minimum width for cards */
}

.card h4 {
    margin: 0 0 8px; /* Space below heading */
}

.card p {
    font-size: 1.5em; /* Larger font for value */
    margin: 0; /* No margin for value */
}

.service-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Space between cards */
}

.service-card {
    background: #e0f7fa; /* Light blue background */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Inner spacing */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Default shadow */
    cursor: pointer; /* Pointer cursor on hover */
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
    flex: 1 1 calc(30% - 15px); /* Responsive card size */
    min-width: 150px; /* Minimum width for cards */
}

.service-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.service-card h4 {
    margin: 0; /* No margin for heading */
    text-align: center; /* Center align text */
}

.dashboard-footer {
    background-color: #4CAF50; /* Footer background color */
    color: white; /* Text color */
    text-align: center; /* Center align text */
    padding: 10px; /* Padding around footer */
}

.loading, .error {
    text-align: center; /* Center align text for loading/error */
    margin-top: 20px; /* Space above */
}

.logout-button {
    background-color: #f44336; /* Logout button background color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    padding: 10px 15px; /* Padding for button */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
}

.logout-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

.homelab-architecture {
    margin: 20px 0; /* Space above and below the section */
    text-align: center; /* Center the heading and image */
}

.image-container {
    width: 100%; /* Full width of the container */
    max-width: 800px; /* Set a maximum width */
    height: 400px; /* Fixed height for the image container */
    margin: 0 auto; /* Center the container */
    overflow: hidden; /* Hide overflow */
}

.architecture-image {
    width: 100%; /* Make image responsive to container width */
    height: auto; /* Maintain aspect ratio */
    max-height: 100%; /* Ensure it doesn't exceed container height */
}
